<template>
    <div style="width:100%">
        <app-image></app-image>
        <div class="newsDiv" v-if="false">
            <div class="newsContent">
                <el-row >
                <el-col :span="3" ><span class="newsTitle">新闻动态</span></el-col>
                <el-col :span="21" class="newsInfoDiv">
                    <el-row style="margin-top:20px" v-for="(item,i) in newslist" :key="i">
                        <el-col :span="20">
                            <!-- <a :href="'/aboutNewsInfo?type=index&id='+item.id">{{item.title}}</a> -->
                        <router-link :to="{name: 'aboutNewsInfo', query: { type: 'index',id:item.id }}">{{item.title}}</router-link>
                            </el-col>
                        <el-col :span="4">{{item.date}}</el-col>
                    </el-row>
                </el-col>
                </el-row>
            </div>
        </div>
        <section class="sec1">
            <img class="logo" src="../static/home1/logo.png" alt="">
            <div class="sec1-p">卓越的软件及服务提供商，助力企业数字化转型</div>
            <div class="sec1-p">拥有完整的产品研发、销售和技术服务团队</div>
            <div class="sec1-p">中关村高新技术企业、国家级高新技术企业</div>
        </section>
        <section class="sec2">
            <div class="sec2-div" :style="{'height':windowH+'px'}" v-for="(item,index) in sec2List" :key="index">
                <div @click="sec4path(item)">
                    <img class="sec2-bg" :style="{'height':windowH+'px'}" :src="item.img" alt="">
                    <div class="sec2-content">
                        <div class="sec2-name">{{item.name}}</div>
                        <div class="sec3-p">{{item.des}}</div>
                    </div>
                </div>
                
            </div>
        </section>
        <section class="sec3">
            <div class="item" v-for="(item,index) in sec3List" :key="index">
                <!-- <img class="sec3-bg" :src="item.img" alt=""> -->
                <div class="sec3-name">{{item.name}}<span>{{item.dan}}</span> </div>
                <div class="sec3-p" v-for="(item1,index1) in item.deslist" :key="index1">{{item1}}</div>
            </div>
        </section>
        <section class="sec4">
            <div class="sec4-title">自研软件</div>
            <div class="list">
                <div class="item" v-for="(item,index) in sec4List" :key="index">
                    <img class="item-img" :src="item.img" alt="">
                    <div class="item-posi">{{item.name}}</div>
                    <div class="item-content" @click="sec4path(item)">
                        <div class="item-name">{{item.name}}</div>
                        <div class="item-des">{{item.des}}</div>
                        <div class="item-btn">查看详情</div>
                    </div>
                </div>
            </div>
        </section>
        <section class="sec5">
            <div class="sec5-title">代理软件</div>
            <div class="list">
                <div class="item" v-for="(item,index) in sec5List" :key="index" @click="sec4path(item)">
                    <img class="item-img" :src="item.img" alt="">
                    <div class="item-div">
                        <div class="item-name">{{item.name}}</div>
                    </div>
                </div>
            </div>
        </section>
        <!--  -->
        <div class="company">
            <div class="companyDiv">
                <div class="companyTitle">我们的客户</div>
                <div class="companyContent">
                    <div class="aqImgDiv" v-for="(item,i) in exampleData" :key="i">
                        <img :src="item"/>
                    </div>
                </div>
                <div class="companyMore" ></div>
            </div>
        </div>
    </div>
</template>
<script>
import AppImage from '../components/common/image.vue'
export default {
    name:"home",
    components:{AppImage},
    data(){
        return{
             exampleData:[
                require("../static/home1/logo1.png"),
                require("../static/home1/logo2.png"),
                require("../static/home1/logo3.png"),
                require("../static/home1/logo4.png"),
                require("../static/home1/logo5.png"),
                require("../static/home1/logo6.png"),
                require("../static/home1/logo7.png"),
                require("../static/home1/logo8.png"),
                require("../static/home1/logo9.png"),
                require("../static/home1/logo10.png"),
                require("../static/home1/logo11.png"),
                require("../static/home1/logo12.png"),
            ],
             newslist:[
                {
                    id:"n1",
                    date:"2021-03-24",
                    title:"Adobe 携手全球商业领袖成立国际顾问委员会，助力企业在数字经济时代蓬勃发展",
                    content:`
                    中国  北京一2021 年 3 月24 日一随着各行各业都在转变策略以适应数字经济时代的全新规则  Adobe 于近日宣布成立其首个国际顾问委员会，
                    进一步帮助客户进行业务革新并制定数字化转型策略，
                    以在客户体验为主导的新时代中脱颖而出Adobe所成立的国际顾问委员会汇集了六位专注于国际市场的领导者，
                    他们在B2B、B2C 和政府领域的革新和转型中具备非常丰富的领导经验。
                    `,
                    imgUrl:require("../static/aboutNews1.png")
                },
                 {
                    id:"n2",
                    date:"2021-01-22",
                    title:"2021欧特克年度设计峰会线上开启 全方位探索数字技术创新价值",
                    content:`
                    2021年1月22日 – 今天，以 “重塑无限 创想未来” 为主题的 2021欧特克年度设计峰会以数字化的形式成功举办，
                    来自大中华区的工程建设业、制造业、传媒和娱乐业的众多创新者、设计精英和业界专家相聚云端，共同分享数字化转型的现状与机遇，
                    洞察领先技术的应用实践与前景，为线上的近万名观众打造了一场收获颇丰的创新盛宴，
                    勾画出数字未来的美好愿景。
                    `,
                    imgUrl:require("../static/aboutNews1.png")
                },
                 {
                    id:"n3",
                    date:"2021-03-25",
                    title:"Chaos Cloud education packages released",
                    content:`
                    Discounts on Chaos Cloud credits for all educational customers, 
                    plus 10 Chaos Cloud credits included in V-Ray Education Collection student and educator licenses.
                    `,
                    imgUrl:require("../static/aboutNews1.png")
                }
            ],
            busList:[
                {
                    id:1,
                    name:"rjdl",
                    title:"软件代理",
                    text1:`取得国内及国际多地软件授权，代理多款全球领先的优秀软件产品，产品涉及设计类、工程类、办公类、数字媒体类、统计分析类、软件开发生命周期管理类、实时渲染类、流程自动化类、数据可视化类等。
                    专业的销售、全面的实施、贴心的服务，为您的业务开展保驾护航。`,
                    text2:`详情请来电咨询，或进入产品中心 -> 代理产品处了解。`,
                    imgUrl:require('../static/home/rjdl.png')
                },
                {
                    id:2,
                    name:"rjjf",
                    title:"软件交付",
                    text1:`建立了高效完整的服务体系，拥有专业的研发、销售和维护团队，资深团队骨干曾任职于知名大型互联网和软件公司。
                    自主研发产品涉及金融、地质、医疗、节能环保、建筑、信息技术等多个行业。为企业的数字化转型服务提供了有力支撑。`,
                   
                    text2:`详情请来电咨询，或进入产品中心 -> 自研产品处了解。`,
                    imgUrl:require('../static/home/rjjf.png')
                },
                {
                    id:3,
                    name:"dsj",
                    title:"大数据&物联网开发",
                    text1:`稳健成熟的专家团队致力于企业的战略、流程、数据和技术转型，以云计算、大数据和物联网技术为基础，
                    打造行业内部敏捷、高效的业务中台，为企业的业务创新提供了强有力的支持。`,
                    text2:`详情请来电咨询，或进入解决方案处了解。`,
                    imgUrl:require('../static/home/dsj.png')
                },
                {
                    id:4,
                    name:"zxfw",
                    title:"软件咨询服务",
                    text1:`提供系统实施的管理咨询，在深入了解客户需求后，提出可行实用的解决方案。
                    公司利用深厚的技术储备完成了多个大型项目，丰富的开发经验和项目实战，有助于通过信息化手段助力企业开源节流，
                    提升竞争力。`,
                    text2:`详情请来电咨询。`,
                    imgUrl:require('../static/home/zxfw.png')
                },
                
            ],
            busType:'rjdl',
            busObj:{},
            anliList:[],
            caseList:[
                {
                    label:'Adobe：',
                    intro:'Photoshop,IIIustrator,Affer Effects,Premiere Pro,  Acrobat Pro···'
                },
                {
                    label:'Chaosgroup：',
                    intro:'V-Rray'
                },
                {
                    label:'Autodesk：',
                    intro:'AutoCAD,Revit,3ds Max,Maya ···'
                },
                {
                    label:'Materialise：',
                    intro:'Mimics Innovation Suite,Materialise Magics;'
                },
                {
                    label:'Minitab：',
                    intro:'Minitab Statistical Software'
                },
                {
                    label:'3D Systems：',
                    intro:'Geomagic Design X'
                },
            ],
            sec2Index:null,
            sec2List:[
                {
                    img:require("../static/home1/img1.jpg"),
                    name:'代理软件',
                    path:'http://www.couponcad.com/productResearch/agent',
                    des:'取得国内及国际多地软件授权，代理多款全球领先的优秀软件产品，产品涉及设计类、工程类、办公类、数字媒体类、统计分析类、软件开发生命周期管理类、实时渲染类、流程自动化类、数据可视化类等。专业的销售、全面的实施、贴心的服务，为您的业务开展保驾护航。'
                },
                {
                    img:require("../static/home1/img2.jpg"),
                    name:'自研软件',
                    path:'http://www.couponcad.com/productResearch/self',
                    des:'公司是国家级高新技术企业，通过了 ISO9001。我们专注于为客户提供专业化的软件开发和技术服务。在金融、互联网、传媒娱乐、建筑等行业积累了丰富的应用经验，公司拥有多项自主研发的核心产品。'
                },
            ],
            sec3List:[
                {
                    img:require("../static/home1/icon1.png"),
                    name:'2016',
                    dan:'年',
                    deslist:[
                    '公司正式成立',
                    '注册资金1100万'
                    ]
                },
                {
                    img:require("../static/home1/icon2.png"),
                    name:'6',
                    dan:'家',
                    deslist:[
                    '在上海、广州、深圳、成都、无锡设立分公司，形成了以北京为中心，覆盖全国的销售和服务网络',
                    ]
                },
                {
                    img:require("../static/home1/icon3.png"),
                    name:'30',
                    dan:'款',
                    deslist:[
                    '成为超过30款知名软件',
                    '品牌的授权代理商'
                    ]
                },
                {
                    img:require("../static/home1/icon4.png"),
                    name:'600',
                    dan:'个',
                    deslist:[
                    '为超过600个客户提供软件应用、云计算、大数据、BI等领域的解决方案和产品交付服务'
                    ]
                },
            ],
            sec4List:[
                {
                    img:require("../static/home1/ruan1.jpg"),
                    name:'DATAWISE',
                    des:'围绕"数据"提供专业的数据服务，覆盖数据采集、数据治理、数据建模、数据作业调度、数据资源管理、数据资产、数据可视化应用等各个环节，支撑业务应用，发挥数据价值，打造大数据应用服务体系',
                    path:'http://www.couponcad.com/productInfo?id=s4&name=Datawise&page=self'
                },
                {
                    img:require("../static/home1/ruan2.jpg"),
                    name:'APIGATEWAY',
                    des:'作为 API 统一管控工具，快速实现互通互联和系统间集成对接；以 API 为核心，围绕 API 生命周期的各个阶段实现对 API 的统一管理，同时提供统一鉴权、统一流控、安全防护、统一监控等，保障服务安全稳定。',
                    path:'http://www.couponcad.com/productInfo?id=s6&name=APIGateway&page=self'
                },
                {
                    img:require("../static/home1/ruan3.jpg"),
                    name:'NGINXWISE',
                    des:'提供了一个统一管理门户，实现nginx集群的集中管理、在线配置、运维监测，同时基于NGINX+LUA的技术框架，实现业务流量的灰度控制，支撑业务需求',
                    path:'http://www.couponcad.com/productInfo?id=s1&name=Nginxwise&page=self'
                },
            ],
            sec5List:[
                {
                    img:require("../static/home1/dai1.png"),
                    name:'Adobe',
                    path:'http://www.couponcad.com/productResearch/agent'
                },
                {
                    img:require("../static/home1/dai2.png"),
                    name:'Autodesk',
                    path:'http://www.couponcad.com/productResearch/agent',
                },
                {
                    img:require("../static/home1/dai3.png"),
                    name:'Minitab',
                    path:'http://www.couponcad.com/productResearch/agent',
                },
                {
                    img:require("../static/home1/dai4.png"),
                    name:'Microsoft',
                    path:'http://www.couponcad.com/productResearch/agent',
                },
                {
                    img:require("../static/home1/dai5.png"),
                    name:'QT',
                    path:'http://www.couponcad.com/productResearch/agent'
                },
                {
                    img:require("../static/home1/dai6.png"),
                    name:'Chaosgroup',
                    path:'http://www.couponcad.com/productResearch/agent',
                },
                {
                    img:require("../static/home1/dai7.png"),
                    name:'Vmware',
                    path:'http://www.couponcad.com/productResearch/agent',
                },
                {
                    img:require("../static/home1/dai8.png"),
                    name:'更多',
                    path:'http://www.couponcad.com/productResearch/agent',
                },
            ],
            windowH:0
        }
    },
    methods:{
        init(){
            let list=[
                {
                    title:"NginxWise-地产公司",
                    content:`方案：通过NGINXWISE构建的弹性伸缩的应用交付层，实现NGINX集群的高可用，实现业务流量的灵活控制（URL分流、灰度发布、限流等），支撑业务需求。
                        NGINXWISE实现NGINX集群的统一纳管，提供监控、配置管理、流量分析等能力。
                       <br/> 收益：提升应用交付的敏捷性，满足业务快速迭代、持续交付需求。
                        丰富的灰度发布、限流等流量精细化控制解决方案满足应用定制化需求，提升业务高可用性的同时降低生产运维风险。`,
                    imageUrl: require("../static/home/anli-1.png"),
                },
                 {
                    title:"NginxWise-金融公司",
                    content:`方案：带Wallarm WAF模块的NGINXWISE,自建IDC、私有云供应商的DNS /全局负载均衡（GSLB）,遍布多地的多云部署。
                       <br/>收益： 部署灵活性和对多个云平台的支持，易于使用，数小时或数天即可运行，为用户带来更好的体验，
                       无需CDN费用即可降低成本，减少合作伙伴ISP的网络流量。`,
                    imageUrl: require("../static/home/anli-2.png"),
                },
                 {
                    title:"CoWise-地产公司财务部",
                    content:`方案：财务智能决策在线分析看板，
                        集团财务SAP、U8、ERP等业务系统同步进行。
                       <br/> 实时财务数据和经营数据抽取更新，实时数据汇集和计算。
                        企业数据模型在线分析、支持海量，
                        建立了企业指标库，
                        通过数据驱动器完成4家子公司500多张数据表接入。
                        `,
                    imageUrl: require("../static/home/anli-3.png"),
                    },
                 {
                    title:"DataWise-地产公司",
                    content:`方案：项目数据收集及统计，
                        项目进度把控，
                        人员权限管理，
                        数据管理。
                        <br/>
                        收益：记录建筑项目全生命周期数据，
                        全面把控项目实施进度，
                        及时跟踪项目风险，消除建设安全隐患，
                        积累丰富的项目经验，为其他项目建设提供经验指导。
                        `,
                    imageUrl: require("../static/home/anli-4.png"),
                },
                 {
                    title:"DataWise-国有企业",
                    content:`方案：供应商评价与管理制度体系建设，
                        供应商评价与管理应用模式落地，
                        供应商评价与管理平台建设。
                       <br/> 
                       收益：制定供应商的准入、评价、分级、退出规则，
                        建设集团供应商库，
                        对供应商实施分级管理，
                        保障采购工作质量，防范供应风险。
                       `,
                    imageUrl: require("../static/home/anli-5.png"),
                },
                 {
                    title:"IoTWise-医院",
                    content:`方案：设备数据监测、采集，
                        医用数据统计分析，
                        设备管理，
                        权限管理。
                       <br/> 
                        收益：实时监测医用污水的余氯含量，并做预警，
                        实时监测医用设备的运行状态，
                        医用数据在线分析看板。
                        `,
                    imageUrl: require("../static/home/anli-6.png"),
                }
            ]
            this.anliList=list
            this.busObj=this.busList[0]
        },
        turnToSolution(){
            this.$router.push({
                name:"solutionView",
                params:{
                    type:"jrrpa"
                }
            })
        },
        turnToExample(){
            this.$router.push({
                name:"exampleMember",
            })
        },
        yewuClick(val){
            this.busType=val
            let list=this.busList
            let item=list.filter(item=>item.name==val)
            this.busObj=item[0]
            // this.busObj = Object.assign({}, item) 
            // this.$set(this.busObj,'title',item.title)
            // this.$set(this.busObj,'text1',item.text1)
            // this.$set(this.busObj,'text2',item.text2)
            // this.$set(this.obj,'k1','v1')
            this.$forceUpdate()
        },
        sec2click(index){
            this.sec2Index = index;
        },
        sec4path(item){
            if(!item.path){
                return false
            }
            window.open(item.path)
        }
    },
    created(){
        let windowWidth = document.documentElement.clientWidth; //获取屏幕宽度
        windowWidth = windowWidth>=1300?windowWidth:1300
        this.windowH = windowWidth * (529/1920)
        this.init()
    }
}
</script>
<style lang="less">
@font-face {
  font-family: "YouSheBiaoTiHei";
  src: url("../assets/font/YouSheBiaoTiHei.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.newsDiv{
    width:100%;
    background-color:#f9f9f9;
    font-size:14px
}
.company{
    width: 90%;
    margin: 0 auto;
    margin-top: 90px;
    margin-bottom: 90px;
}
.anliDiv .anliMore,.companyDiv .companyMore{
    font-size:14px;
    color:#404756;
    text-align: center;
    margin: 30px auto;
    cursor:pointer;
}
.companyContent {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
    margin-top: 35px;
}
.companyContent .borderStyle{
    border:1px solid #e1e1e1;
    width:90%;
    
}
.companyContent .aqImgDiv{
    margin-top:17px;
    width: 24%;
    // height: 91px;
}
.companyContent img{
    width: 100%;
    // height: 91px;
}
.busTriangle{
    width: 0;
    margin: 0 auto;
    border-bottom: 20px solid #f7fbfe;
    border-left: 20px solid transparent;
    border-top: 20px solid transparent;
    border-right: 20px solid transparent;
}
.bus1{
    font-size: 36px;padding: 40px 0;
}
.yewuImage{
    margin-left: -100px;
    background-color: rgb(255, 255, 255);
    box-shadow: #dddddd 2px 2px 15px 5px;
    padding: 50px;
    text-align: left;
    font-size: 16px;
    line-height:24px;
    position: relative;
    z-index: 1;
}
.swi-box{
    position: relative;
}
.swi-box .swi-bg{
    width: 100%;
    height: 420px;
    /* background-image: url('../static/home/banner2.jpg');
    background-size: cover;
    background-position: 50% 50%; */
    
    /* background-repeat: no-repeat; */
}
.swi-warp .el-carousel__item img {
    opacity: 1;
} 
.swi-div{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: left;
    padding-left: 40px;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
}

.title{
    font-size: 28px;
    font-family: PangMenZhengDao;
    font-weight: normal;
    color: #FFFFFF;
}
.list{
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
}
.item{
    width: 440px;
    margin-bottom: 16px;
}
.item-label{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
}
.item-intro{
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.4;
    margin-top: 5px;
}

.sec1{
    text-align: center;
    padding-bottom: 4%;
    padding-top: 4%;
    font-size: 0;
    .logo{
        width: 106px;
        // height: 59px;
        margin-bottom: 2%;
        font-size: 0;
    }
    .sec1-p{
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 1.6;
    }
}
.sec2{
    display: flex;
    .sec2-div{
        width: 50%;
        position: relative;
        height: 529px;
        overflow: hidden;
        transition: width 0.5s;
        float: left;
        // @media only screen and (max-width: 2000px) {
        //     height: 529px;
        // }
        // @media only screen and (max-width: 1700px) {
        //     height: 450px;
        // }
        // @media only screen and (max-width: 1400px) {
        //     height: 390px;
        // }
        &:hover{
            width: 70%;
            .sec3-p{
                opacity: 1;
                // width: 50%;
                height: auto;
            }
            .sec2-content{
                bottom: 13%;
            }
            .sec2-name{
                &::after{
                    opacity: 0;
                    width: 0;
                }
            }
        }
        .sec2-bg{
            // width: 1237px;
            height: 100%;
            // @media only screen and (max-width: 2000px) {
            //     height: 529px;
            // }
            // @media only screen and (max-width: 1700px) {
            //     height: 450px;
            // }
            // @media only screen and (max-width: 1400px) {
            //     height: 390px;
            // }
        }
        .sec2-content{
            position: absolute;
            bottom: 50%;
            left: 0px;
            padding-left: 74px;
        }
        .sec2-name{
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            position: relative;
            display: inline-block;
            transition: all 0.3s ease;
            &::after{
                content: '';
                display: block;
                opacity: 1;
                width: 90%;
                height: 5px;
                background: #FFFFFF;
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
                transition: all 0.3s ease;
            }
        }
        .sec3-p{
            width: 28vw;
            height: 0;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 32px;
            opacity: 0;
            text-align: justify;
            transition: all 0.3s ease;
        }
    }
}
.sec3{
    display: flex;
    justify-content: space-around;
    // width: 1300px;
    background: linear-gradient(180deg, #F1F5FB, #FFFFFF);
    border: 2px solid #FFFFFF;
    // box-shadow: 0px 10px 22px 2px rgba(176,189,219,0.34);
    border-radius: 6px;
    background: #F3F6FB;
    padding: 0 10%;
    padding-top: 3%;
    padding-bottom: 1.5%;
    .item{
        flex: 1;
        text-align: center;
        .sec3-bg{
            width: 52px;
            height: 52px;
        }
        .sec3-name{
            font-size: 44px;
            font-family: YouSheBiaoTiHei;
            font-weight: 400;
            color: #282D3B;
            margin-bottom: 2%;
            // margin-top: 16px;
            span{
                margin-left: 14px;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 600;
                color: #282D3B;
                line-height: 20px;
                vertical-align: 4px;
            }
        }
        .sec3-p{
            margin: 0 auto;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #677086;
            line-height: 26px;
            width: 80%;
            text-align: center;
        }
    }
}
.sec4{
    margin-top: 5%;
    .sec4-title{
        text-align: center;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
    }
    .list{
        display: flex;
        margin-top: 55px;
        .item{
            position: relative;
            // width: 640px;
            // height: 425px;
            overflow: hidden;
            font-size: 0;
            flex: 1;
            &:hover{
                .item-content{
                    // bottom: auto;
                    bottom: 0;
                }
                .item-img{
                    transform: scale(1.05);
                }
                .item-posi{
                    opacity: 0;
                }
            }
            .item-img{
                width: 100%;
                transition: all 0.8s ease;
            }

            .item-posi{
                width: 100%;
                height: 63px;
                background: rgba(0, 27, 77, 0.66);
                position: absolute;
                left: 0;
                bottom: 0;
                transition: all 0.3s ease;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 28px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                opacity: 1;
                color: #FFFFFF;
            }
            
            .item-content{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                bottom: -100%;
                z-index: 10;
                transition: all 0.3s ease;
                text-align: center;
                padding-top: 10%;
                box-sizing: border-box;
                background: rgba(0, 27, 77, 0.3);
            }
            .item-name{
                font-size: 28px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
            }
            .item-des{
                margin: 0 auto;
                margin-top: 5%;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                width: 80%;
                text-align: justify;
            }
            .item-btn{
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 136px;
                height: 38px;
                border: 1px solid #FFFFFF;
                border-radius: 4px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                position: absolute;
                left: 50%;
                bottom: 11%;
                transform: translateX(-50%);
                cursor: pointer;
                transition: all 0.3s ease;
                &:hover{
                    opacity: 0.8;
                }
            }
        }
    }
}

.sec5{
    margin-top: 5%;
    
    .sec5-title{
        text-align: center;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
    }
    .list{
        margin-top: 60px;
        padding-bottom: 4%;
        background: #F3F6FB;
        .item{
            width: 25%;
            position: relative;
            margin-bottom: 0;
            font-size: 0;
            overflow: hidden;
            cursor: pointer;
            &:hover{
                .item-div{
                    opacity: 1;
                }
                .item-img{
                    transform: scale(1.05);
                }
            }
            .item-img{
                width: 100%;
                display: block;
                transition: all 0.8s ease;
            }
            .item-div{
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                // opacity: 0;
                transition: all 0.3s ease;
            }
            .item-name{
                font-size: 30px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}
.companyTitle{
    text-align: center;
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
}
</style>